import { Helmet } from "react-helmet";
import TagManager from "react-gtm-module";
import { Divider, Slider, Typography } from "@mui/material";
import { useState, useMemo, useCallback, useEffect, useRef } from "react";
import { contents, notice } from "src/assets/lang/contents";
import Button from "src/components/atoms/Button";
import ImageBanner from "src/components/modules/ImageBanner";
import { homeBannerImages } from "src/utils/constants/homeBannerImageList";
import MasonryImageList from "src/components/modules/MansoryImageList";
import CustomizedTables from "src/components/modules/Table";
import MainTemplate from "src/components/templates/MainTemplate";
import {
  FlexColBox,
  FlexColCenterBox,
  FlexWrapCenterBox,
} from "src/styles/styledComponentList";
import { popUpReservationPage, popUpMap } from "src/utils/functions";
import usePhrase from "src/utils/hooks/usePhrase";
import Header from "../../components/modules/Header";
import {
  Article,
  SectionTitle,
  ArticleTitle,
  ArticlePhrases,
  MainPhraseOnSlide,
  TextOnSlide,
  StyledOl,
  Box,
  StyledUl,
} from "./styles";
import MediaQuery from "src/utils/hooks/useMediaQuery";
import KakaoMap from "src/components/modules/KakaoMap";
import Footer from "src/components/modules/Footer";
import RoomsList from "src/components/modules/RoomsList";
import {
  refundInfoHeadCells_en,
  reservatinoInfoHeadCells_ko,
} from "src/utils/constants/headCells";
import {
  refundData_ko,
  reservationInfo,
  roomData_ko,
} from "src/utils/constants/Data";
import { FormattedMessage } from "react-intl";
import Modal from "src/components/atoms/Modal";
import { images as specialtyImages } from "src/utils/constants/specialtyImageList";
import RadioButtons from "src/components/modules/RadioButtons";
import Card from "src/components/modules/Card";
import BasicTabs from "src/components/modules/Tabs";

interface innerContents {
  label: string;
  title: string;
  contents: string;
}

interface rooms {
  name: string;
  defaultPeopleNum: string;
  maxPeopleNum: string;
  price: {
    "high-season": {
      weekend: string;
    };
    "off-season": {
      weekdays: string;
      weekend: string;
    };
  };
}

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "5hp-project",
    page: "home",
  },
  dataLayerName: "PageDataLayer",
};
export function MainPage() {
  TagManager.dataLayer(tagManagerArgs);
  const { isMobile, isTablet } = MediaQuery();
  const [getMsgWithId] = usePhrase();
  const [contentsData, setContentsData] = useState([]);
  const [isRoomInfoModalOpen, setIsRoomInfoModalOpen] = useState(false);
  const [roomIndex, setRoomIndex] = useState<number>(0);
  const [isSpecialtyModalOpen, setIsSpecialtyModalOpen] = useState(false);
  const [specialtyIndex, setSpecialtyIndex] = useState<number>(0);

  const [recreationSliderOption, setRecreationSliderOption] = useState({
    dots: true,
    infinite: true,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 700,
    autoplaySpeed: 2000,
    pauseOnDotsHover: true,
    arrows: isMobile ? false : true,
    focusOnSelect: true,
  });

  const roomSliderOption = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 800,
    autoplaySpeed: 1200,
    pauseOnDotsHover: true,
    arrows: isMobile ? false : true,
    focusOnSelect: true,
  };

  const articleListRef = useRef([]);

  const contentsLength = useMemo(
    () => Number(getMsgWithId("menu_length", "0")),
    [getMsgWithId]
  );

  const getContentsData = useCallback(() => {
    const contents = [];
    for (let i = 0; i < contentsLength; i++) {
      const contentsItem: innerContents = {
        label: "",
        title: "",
        contents: "",
      };
      contentsItem.label = getMsgWithId("menu." + i + ".label") as string;
      contentsItem.title = getMsgWithId("menu." + i + ".title") as string;
      contentsItem.contents = getMsgWithId("menu." + i + ".contents") as string;
      contents.push(contentsItem);
    }
    return contents;
  }, [contentsLength, getMsgWithId]);

  useEffect(() => {
    !isNaN(contentsLength) && setContentsData(getContentsData());
  }, [contentsLength, getContentsData]);

  useEffect(() => {
    isMobile
      ? setRecreationSliderOption((prev) => {
          return {
            ...prev,
            slidesToShow: 1,
            arrows: false,
          };
        })
      : setRecreationSliderOption((prev) => {
          return {
            ...prev,
            slidesToShow: 3,
            arrows: true,
          };
        });
  }, [isMobile]);

  const tabContents = [
    <Slider>
      <Card
        innerContents="옿잉"
        title="즐길거리"
        alt={"이미지"}
        imageSrc={"../images/main_mini2.jpg"}
        imageHeight="52vh"
      />
      <Card
        innerContents="옿잉"
        title="즐길거"
        alt={"이미지"}
        imageSrc={"../images/main_mini1.jpg"}
        imageHeight="52vh"
      />
      <Card
        innerContents="옿잉"
        title="즐길"
        alt={"이미지"}
        imageSrc={"../images/main_mini3.jpg"}
        imageHeight="52vh"
      />
    </Slider>,
    <RadioButtons></RadioButtons>,
  ];

  return (
    <MainTemplate
      HeaderSection={<Header scrollRefList={articleListRef} />}
      BannerSection={
        <ImageBanner images={homeBannerImages} altBase="main_image" dim={true}>
          <TextOnSlide>
            <img
              src="./Logo_white.png"
              height={isMobile ? "46px" : "80px"}
              style={{ margin: "10px", objectFit: "fill" }}
              alt="oh happy pension logo"
            />
            <MainPhraseOnSlide variant="h5">
              <FormattedMessage
                id="main_sub_title"
                defaultMessage="Welcome to Oh! Happy"
              />
            </MainPhraseOnSlide>
          </TextOnSlide>
        </ImageBanner>
      }
      FooterSection={<Footer />}
    >
      <Helmet
        title="오해피 펜션 | Oh happy pension"
        meta={[
          {
            name: "description",
            content:
              "오해피, 오해피펜션, 계곡펜션, 수영장펜션, 스키장펜션, 수영장, 보드게임. 오해피 펜션 예약 사이트입니다. 펜션 소개 및 룸 소개 즐길거리 등이 소개 되어 있고, 예약이 가능합니다.",
          },
          {
            name: "charset",
            content: "utf-8",
          },
          {
            name: "keywords",
            content:
              "오해피, 펜션, 펜션예약, 오해피펜션, 계곡펜션, 수영장펜션, 스키장펜션, 수영장, 펜션예약, 펜션 예약",
          },
          { property: "og:type", content: "website" },
          { property: "og:site_name", content: "website" },
          { property: "og:title", content: "오해피 펜션 | Oh happy pension" },
          {
            property: "og:image",
            content: "https://5hp.co.kr/images/main_4.jpg",
          },
          { property: "og:url", content: "https://5hp.co.kr" },
          { property: "og:locale", content: "ko_KR" },
          { property: "og:locale:alternate", content: "en_US" },
        ]}
      >
        <meta charSet="utf-8" />
      </Helmet>
      <section>
        {/* 펜션소개 */}
        <Divider
          ref={(ref) => {
            articleListRef.current[0] = ref;
          }}
        />
        <Article>
          <FlexWrapCenterBox>
            <FlexColCenterBox
              style={{
                margin: "0 auto",
                flexWrap: isMobile ? "wrap" : "nowrap",
              }}
            >
              <FlexColCenterBox
                style={{
                  maxWidth: "380px",
                }}
              >
                <SectionTitle variant="h6">
                  {contentsData[0]?.label}
                </SectionTitle>
                <ArticleTitle variant="h4">
                  {contentsData[0]?.title}
                </ArticleTitle>
              </FlexColCenterBox>
              <ArticlePhrases>
                {contents["ko-KR"].map((item, index) => (
                  <p key={index}>{item}</p>
                ))}
              </ArticlePhrases>
            </FlexColCenterBox>
            <FlexWrapCenterBox>
              <img
                src="../images/main_mini1.jpg"
                width="250px"
                height="400px"
                style={{
                  margin: "10px",
                  objectFit: "fill",
                  boxShadow: "rgb(0 0 0 / 10%) 6px 6px 9px 1px",
                }}
                alt="oh happy pension image1"
              />
              <img
                src="../images/main_mini2.jpg"
                width="250px"
                height="400px"
                style={{
                  margin: "10px",
                  objectFit: "fill",
                  boxShadow: "rgb(0 0 0 / 10%) 6px 6px 9px 1px",
                }}
                alt="oh happy pension image2"
              />
            </FlexWrapCenterBox>
          </FlexWrapCenterBox>
        </Article>
        {/* 객실소개 */}
        <Divider
          ref={(ref) => {
            articleListRef.current[1] = ref;
          }}
        />
        <Article>
          <FlexColBox
            style={{
              margin: "0 auto",
              flexWrap: isMobile ? "wrap" : "nowrap",
              justifyContent: isMobile ? "flex-start" : "center",
            }}
          >
            <SectionTitle variant="h6">{contentsData[1]?.label}</SectionTitle>
            <RoomsList
              roomsData={roomData_ko}
              onClickRoom={(index) => {
                setIsRoomInfoModalOpen(true);
                setRoomIndex(index);
              }}
            />
          </FlexColBox>
        </Article>
        {/* 특별함 */}
        <Divider
          ref={(ref) => {
            articleListRef.current[2] = ref;
          }}
        />
        <Article>
          <FlexColBox
            style={{
              margin: "0 auto",
              flexWrap: isMobile ? "wrap" : "nowrap",
              justifyContent: isMobile ? "flex-start" : "center",
            }}
          >
            <SectionTitle variant="h6">{contentsData[2]?.label}</SectionTitle>
            <MasonryImageList
              cols={isMobile ? 2 : 3}
              gap={isMobile ? "12px" : "24px"}
              borderRadius={isMobile ? "20%" : "10%"}
              onClick={(index) => {
                setIsSpecialtyModalOpen(true);
                setSpecialtyIndex(index);
              }}
              images={specialtyImages}
            />
          </FlexColBox>
        </Article>
        {/* 즐길거리 */}
        {/* <Divider
          ref={(ref) => {
            articleListRef.current[3] = ref;
          }}
        />
        <Article>
          <FlexColBox
            style={{
              margin: "0 auto",
              flexWrap: isMobile ? "wrap" : "nowrap",
              justifyContent: isMobile ? "flex-start" : "center",
            }}
          >
            <SectionTitle variant="h6">{contentsData[3]?.label}</SectionTitle>
          </FlexColBox>
          <BasicTabs tabContents={tabContents} />
        </Article> */}

        {/* 오시는길 */}
        <Divider
          ref={(ref) => {
            articleListRef.current[3] = ref;
          }}
        />
        <Article>
          <FlexColBox
            style={{
              margin: "0 auto",
              flexWrap: isMobile ? "wrap" : "nowrap",
              justifyContent: isMobile ? "flex-start" : "center",
            }}
          >
            <SectionTitle variant="h6">{contentsData[3]?.label}</SectionTitle>
          </FlexColBox>
          <Button onClick={popUpMap} radius={5} style={{ float: "right" }}>
            카카오 맵으로 길찾기
          </Button>
          <KakaoMap />
          <ArticlePhrases>{contents[contentsData[3]?.contents]}</ArticlePhrases>
        </Article>

        <Divider
          ref={(ref) => {
            articleListRef.current[4] = ref;
          }}
        />

        {/* 예약안내 */}
        <Article>
          <FlexColBox
            style={{
              margin: "0 auto",
              flexWrap: isMobile ? "wrap" : "nowrap",
              justifyContent: isMobile ? "flex-start" : "center",
            }}
          >
            <SectionTitle variant="h6">{contentsData[4]?.label}</SectionTitle>
            <Button
              onClick={popUpReservationPage}
              radius={5}
              style={{ alignSelf: "flex-end" }}
            >
              예약하기
            </Button>
            <CustomizedTables
              headCells={reservatinoInfoHeadCells_ko}
              data={roomData_ko}
              isRoomInfo={true}
            />
            <Box>
              <StyledUl>
                <li>
                  <strong>예금주</strong> :
                  {` ${reservationInfo.account_holder} /
                  ${reservationInfo.account_number_info}`}
                </li>
                <li>
                  <strong>예약문의</strong> :
                  {reservationInfo.information_number}
                </li>
                <li>
                  <strong>객실정보</strong> : {reservationInfo.room_info}
                </li>
                <li>
                  <strong>부대시설</strong> : {reservationInfo.amenities}
                </li>
                <li>
                  <strong>이용시간</strong> : {reservationInfo.hours_of_use}
                </li>
              </StyledUl>
            </Box>

            <Box>
              <Typography
                style={{
                  margin: "36px 0 12px",
                }}
                variant="h5"
                component="h3"
              >
                유의사항
              </Typography>
              <StyledOl>
                {notice["ko-KR"].map((item) => (
                  <li>{item}</li>
                ))}
              </StyledOl>
            </Box>
            <SectionTitle variant="h6" style={{ marginTop: "36px" }}>
              환불규정 안내
            </SectionTitle>
            <CustomizedTables
              headCells={refundInfoHeadCells_en}
              data={refundData_ko}
            />
          </FlexColBox>
        </Article>
      </section>

      <Modal
        style={{ maxWidth: "968px", width: "100%" }}
        isOpen={isRoomInfoModalOpen}
        overflow="none"
        rootID="root-modal"
        onModalClose={() => setIsRoomInfoModalOpen(false)}
        bgrColor="transparent"
      >
        <FlexWrapCenterBox>
          <img
            src="./Logo_white.png"
            height="50px"
            style={{ margin: "10px", objectFit: "fill" }}
            alt="oh happy pension logo"
          />

          <Typography
            style={{
              margin: "0 12px",
              color: "white",
            }}
            variant="h5"
            component="h1"
          >
            {roomData_ko[roomIndex].name}
          </Typography>
        </FlexWrapCenterBox>
        <ImageBanner
          sliderOptions={roomSliderOption}
          images={roomData_ko[roomIndex].images}
          altBase="rooms_image"
        />
      </Modal>
      <Modal
        style={{ maxWidth: "968px", width: "100%" }}
        isOpen={isSpecialtyModalOpen}
        overflow="none"
        rootID="root-modal"
        onModalClose={() => setIsSpecialtyModalOpen(false)}
        bgrColor="transparent"
      >
        <FlexWrapCenterBox>
          <img
            src="./Logo_white.png"
            height="50px"
            style={{ margin: "10px", objectFit: "fill" }}
            alt="oh happy pension logo"
          />

          <Typography
            style={{
              margin: "0 12px",
              color: "white",
            }}
            variant="h5"
            component="h1"
          >
            {specialtyImages[specialtyIndex].title}
          </Typography>
        </FlexWrapCenterBox>
        <ImageBanner
          sliderOptions={roomSliderOption}
          images={specialtyImages[specialtyIndex].images}
          altBase="specialty_image"
        />
      </Modal>
    </MainTemplate>
  );
}

// "menu_length": "5",
// {
//   "link": "/recreation",
//   "label": "즐길거리",
//   "title": "가족 테마 낚시 펜션",
//   "contents": "가족 단위의 펜션 예"
// },
