import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { FlexWrapCenterBox } from "src/styles/styledComponentList";
import stylesConstants from "src/styles/stylesConstants";

export const Article = styled.article`
  width: 100%;
`;

export const TextLogo = styled(Typography)`
  justify-self: flex-start;
  margin: 0 auto 0 0;
  color: ${stylesConstants.colors.POINT_STRONG_GREY};
  font-weight: 600;
`;

export const SectionTitle = styled(Typography)`
  margin: 12px 0;
  font-weight: 600;
  text-transform: uppercase;
  color: ${stylesConstants.colors.POINT_GOLD2};
`;

export const ArticleTitle = styled(Typography)`
  font-weight: 600;
`;

export const ArticlePhrases = styled(Typography)`
  margin: 12px 0;
  text-align: center;
  font-weight: 400;
  color: ${stylesConstants.colors.POINT_STRONG_GREY};
`;

export const TextOnTop = styled.div`
  display: flex;
  flex-flow: column wrap;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 1000;
`;

export const TextOnSlide = styled.div`
  display: flex;
  flex-flow: column wrap;
  position: absolute;
  text-transform: capitalize;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 1000;

  & > * {
    filter: drop-shadow(0 0 0.1rem #ccfcfc);
  }
`;

export const MainPhraseOnSlide = styled(Typography)`
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  color: white;
  text-align: center;
`;

export const ImageBannerTitle = styled.h3`
  font-size: 16px;
  line-height: 20px;
  margin: 0 22px 20px 22px;
`;

export const StyledOl = styled.ol`
  & > li {
    list-style: decimal;
    margin: 4px 0 0 12px;
  }
`;

export const StyledUl = styled.ol`
  & > li {
    list-style: disc;
    margin: 4px 0 0 12px;
  }
`;
export const Box = styled.div`
  margin: 0 24px;
`;
