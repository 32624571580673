import React from "react";
import styled from "@emotion/styled";
import stylesConstants from "src/styles/stylesConstants";
import { css } from "@emotion/react";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: string;
  hoverColor?: string;
  radius?: number;
  children: React.ReactNode;
  className?: string;
}

/**
 * Base Button 컴포넌트
 * @param {string} color - 버튼의 색상
 * @param {string} hoverColor - 버튼 hover시 색상
 * @param {CSSProperties} style - 버튼의 스타일(색상제외)
 */
export default function Button({
  color,
  className,
  hoverColor,
  children,
  radius,
  style,
  ...props
}: Props) {
  return (
    <StyledButton
      {...props}
      style={style}
      color={color}
      radius={radius}
      hoverColor={hoverColor ? hoverColor : stylesConstants.colors.POINT_GOLD}
      className={className}
    >
      {children}
    </StyledButton>
  );
}

const StyledButton = styled.button<Props>`
  cursor: pointer;
  transition: 0.1s;
  background-color: ${({ color }) =>
    color || `${stylesConstants.colors.POINT_GOLD2}`};
  border: 1px solid ${stylesConstants.colors.POINT_GOLD2} !important;
  padding: 10px 20px;
  width: 200px;
  border-radius: ${({ radius }) => radius}px;
  border: transparent;
  color: white;
  transform: translateY(0);
  margin: 12px 0;

  :hover {
    ${({ hoverColor }) =>
      hoverColor &&
      css`
        background-color: ${hoverColor};
        border: 1px solid ${hoverColor} !important;
      `}
  }

  :active {
    transform: translateY(0);
    box-shadow: unset;
  }
`;
