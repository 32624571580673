import styled from "@emotion/styled";
import { ReactPortal, ReactNode } from "react";
import stylesConstants from "src/styles/stylesConstants";
import MediaQuery from "src/utils/hooks/useMediaQuery";

interface AuxProps {
  children: ReactNode | ReactPortal;
  HeaderSection?: ReactNode;
  BannerSection?: ReactNode;
  FooterSection?: ReactNode;
}

function MainTemplate({
  HeaderSection,
  BannerSection,
  FooterSection,
  children,
}: AuxProps) {
  const { isTablet } = MediaQuery();
  return (
    <>
      {HeaderSection && HeaderSection}
      {BannerSection && BannerSection}
      <MainSection isTablet={isTablet}>{children}</MainSection>
      {FooterSection && <FooterContainer>{FooterSection}</FooterContainer>}
    </>
  );
}
interface mainType {
  isTablet: boolean;
}

const MainSection = styled.main<mainType>`
  position: relative;
  /* margin: 0 ${stylesConstants.size.PC_MARGIN_WIDTH}; */
  padding: 0 16px;
  margin: 0 auto;
  width: 100%;
  max-width: ${stylesConstants.size.PC_CONTENT_WIDTH};
  background-color: transparent;
  box-shadow: none;

  & > section {
    margin: 12% 0;
  }

  & > section > article {
    margin: 10% 0;
  }
`;

const FooterContainer = styled.footer`
  width: 100%;
  height: 100%;
`;

export default MainTemplate;
