import { useState } from "react";
import Radio from "@mui/material/Radio";
import stylesConstants from "src/styles/stylesConstants";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import styled from "@emotion/styled";

export default function RadioButtons() {
  const [selectedValue, setSelectedValue] = useState("a");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <StyledFormControl>
      <StyledFormLabel id="demo-radio-buttons-group-label">
        계절
      </StyledFormLabel>
      <StyledRadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="봄•가을"
        name="radio-buttons-group"
        value={selectedValue}
        onChange={handleChange}
      >
        <FormControlLabel
          value="봄•가을"
          control={
            <Radio
              sx={{
                color: stylesConstants.colors.POINT_GOLD2,
                "&.Mui-checked": {
                  color: stylesConstants.colors.POINT_GOLD2,
                },
              }}
            />
          }
          label="봄•가을"
        />
        <FormControlLabel value="여름" control={<Radio />} label="여름" />
        <FormControlLabel value="겨울" control={<Radio />} label="겨울" />
      </StyledRadioGroup>
    </StyledFormControl>
  );
}

const StyledFormControl = styled(FormControl)`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;

const StyledFormLabel = styled(FormLabel)`
  margin-right: 12px;
`;

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-flow: row wrap;
`;
