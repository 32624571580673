import { Slide, useScrollTrigger } from "@mui/material";

export function flattenMessages(nestedMessages, prefix = "") {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const msg = messages;
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;
    if (typeof value === "string") {
      msg[prefixedKey] = value;
    } else {
      Object.assign(msg, flattenMessages(value, prefixedKey));
    }
    return msg;
  }, {});
}

export function popUpReservationPage() {
  var popUrl = "https://www.yapen.co.kr/external?ypIdx=57230";
  var popOption =
    "top=10, left=10, width=1200, height=1400, status=no, menubar=no, toolbar=no, resizable=no";
  window.open(popUrl, popOption);
}

export function popUpMap() {
  var popUrl =
    "https://map.kakao.com/link/to/오해피펜션,37.48018724994263,128.27488542047723";
  window.location.href = popUrl;
}

export function HideOnScroll(props) {
  const { children, window, isMenuClicked } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide
      appear={false}
      direction="down"
      in={isMenuClicked ? isMenuClicked : !trigger}
    >
      {children}
    </Slide>
  );
}
