const colors = {
  GHOST: "transparent",
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  BASE_DARK: "#242434",
  POINT_GOLD: "#d4a170",
  POINT_GOLD2: "#dfa974",
  POINT_GREY_GOLD: "#bfa994",
  POINT_GREY: "#8c8c9c",
  POINT_STRONG_GREY: "#71717a",
  FOOTER_GREY: "#525464",
};

const size = {
  PC_MARGIN_WIDTH: "calc((100vw - 968px) / 2)",
  PC_CONTENT_WIDTH: "968px",
  // TB_MARGIN_WIDTH: "calc((100vw - 600px) / 2)",
  TB_CONTENT_WIDTH: "768px",
  MB_CONTENT_WIDTH: "375px",
};

export default { colors, size };
