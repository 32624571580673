export const images = [
  {
    img: "../../images/specialty/낚시터2.jpeg",
    images: [
      "../../images/specialty/낚시터1.jpeg",
      "../../images/specialty/낚시터2.jpeg",
      "../../images/specialty/낚시터3.jpeg",
      "../../images/specialty/낚시터4.jpeg",
    ],
    title: "낚시체험장",
  },
  {
    img: "../../images/specialty/계곡3.jpg",
    images: [
      "../../images/specialty/계곡3.jpg",
      "../../images/specialty/계곡.jpg",
      "../../images/specialty/계곡2.jpg",
      "../../images/specialty/계곡4.jpg",
      "../../images/specialty/계곡5.jpg",
      "../../images/specialty/계곡6.jpg",
      "../../images/specialty/계곡7.png",
    ],
    title: "계곡",
  },
  {
    img: "../../images/specialty/수영장3.png",
    images: [
      "../../images/specialty/수영장3.png",
      "../../images/specialty/수영장.jpg",
      "../../images/specialty/수영장2.jpg",
    ],
    title: "수영장",
  },
  {
    img: "../../images/specialty/테라스.jpg",
    images: [
      "../../images/specialty/테라스.jpg",
      "../../images/specialty/전경.png",
      "../../images/specialty/테라스2.png",
    ],
    title: "테라스",
  },
  {
    img: "../../images/specialty/테라스2.png",
    images: ["../../images/specialty/테라스2.png"],
    title: "객실별 전용 바베큐 공간 & 그릴",
  },
  {
    // img: "../../images/specialty/카페.png",
    img: "../../images/specialty/보드게임.png",
    images: [
      // "../../images/specialty/카페.png",
      "../../images/specialty/보드게임.png",
      "../../images/specialty/보드게임2.png",
    ],
    // title: "카페 및 보드게임 대여",
    title: "보드게임 대여",
  },
];
