export const roomData_ko = [
  {
    name: "핑크스톤",
    target: "커플, 3~4인 가족",
    feature: "실내 마루, 벌레차단 테라스 및 바베큐",
    thumbnail:
      "https://user-images.githubusercontent.com/68500631/230694479-84b66289-0ae1-4e25-a231-8c9b706a92e2.jpeg", //1
    defaultPeopleNum: 4,
    maxPeopleNum: 4,
    price: {
      highSeason: {
        weekdays: 200000,
        weekend: 200000,
      },
      offSeason: {
        weekdays: 150000,
        weekend: 180000,
      },
    },
    images: [
      "https://user-images.githubusercontent.com/68500631/230694486-998bbe94-2f5f-41de-9f65-a9c4834812ae.jpeg", //3
      "https://user-images.githubusercontent.com/68500631/230694484-680921ac-4923-4ce6-90db-8c5b1f1adaad.jpeg", //2
      "../images/rooms/핑크스톤6.jpg",
      "../images/rooms/핑크스톤7.jpg",
      "../images/rooms/핑크스톤5.jpg",
      "../images/rooms/핑크스톤4.jpg",
      "../images/rooms/핑크스톤8.jpg",
      "../images/rooms/화장실.jpg",
      "../images/rooms/테라스.jpg",
    ],
  },
  {
    name: "블루로즈",
    target: "커플",
    feature: "HOT 예약인기방, 세련된 인테리어",
    // feature: "독립 공간, 실내 텐트 공간",
    thumbnail: "../images/rooms/블루로즈3.jpg",
    defaultPeopleNum: 2,
    maxPeopleNum: 2,
    price: {
      highSeason: {
        weekdays: 150000,
        weekend: 150000,
      },
      offSeason: {
        weekdays: 100000,
        weekend: 130000,
      },
    },
    images: [
      "../images/rooms/블루로즈1.jpg",
      "../images/rooms/블루로즈2.jpg",
      "../images/rooms/블루로즈3.jpg",
      "../images/rooms/화장실.jpg",
      "../images/rooms/테라스.jpg",
    ],
  },
  {
    name: "그린버드",
    target: "커플, 3~4인 가족",
    feature: "실내 마루, 벌레차단 테라스 및 바베큐",
    thumbnail: "../images/rooms/그린버드4.jpg",
    defaultPeopleNum: 4,
    maxPeopleNum: 4,
    price: {
      highSeason: {
        weekdays: 200000,
        weekend: 200000,
      },
      offSeason: {
        weekdays: 150000,
        weekend: 180000,
      },
    },
    images: [
      "../images/rooms/그린버드1.jpg",
      "../images/rooms/그린버드2.jpg",
      "../images/rooms/그린버드3.jpg",
      "../images/rooms/그린버드4.jpg",
      "../images/rooms/그린버드5.jpg",
      "../images/rooms/그린버드6.jpg",
      "../images/rooms/화장실.jpg",
      "../images/rooms/테라스.jpg",
    ],
  },
  {
    name: "보라네",
    target: "3~4인 가족 혹은 단체",
    feature: "2개 침실, 벌레차단 테라스 및 바베큐",
    thumbnail: "../images/rooms/보라네2.jpg",
    defaultPeopleNum: 4,
    maxPeopleNum: 4,
    price: {
      highSeason: {
        weekdays: 240000,
        weekend: 240000,
      },
      offSeason: {
        weekdays: 180000,
        weekend: 210000,
      },
    },
    images: [
      "../images/rooms/보라네1.jpg",
      "../images/rooms/보라네2.jpg",
      "../images/rooms/보라네3.jpg",
      "../images/rooms/보라네4.jpg",
      "../images/rooms/보라네5.jpg",
      "../images/rooms/보라네6.jpg",
      "../images/rooms/보라네7.jpg",
      "../images/rooms/화장실.jpg",
      "../images/rooms/테라스.jpg",
    ],
  },
  {
    name: "레드옥스",
    target: "3~4인 가족 혹은 단체",
    feature: "일체형 넓은 공간, 소파",
    thumbnail: "../images/rooms/레드옥스2.jpg",
    defaultPeopleNum: 4,
    maxPeopleNum: 4,
    price: {
      highSeason: {
        weekdays: 240000,
        weekend: 240000,
      },
      offSeason: {
        weekdays: 180000,
        weekend: 210000,
      },
    },
    images: [
      "../images/rooms/레드옥스1.jpg",
      "../images/rooms/레드옥스2.jpg",
      "../images/rooms/레드옥스3.jpg",
      "../images/rooms/레드옥스4.jpg",
      "../images/rooms/레드옥스5.jpg",
      "../images/rooms/레드옥스6.jpg",
      "../images/rooms/레드옥스7.jpg",
      "../images/rooms/화장실.jpg",
      "../images/rooms/테라스.jpg",
    ],
  },
];

export const reservationInfo = {
  account_holder: "윤성희",
  account_number_info: "농협 302-5268-0047-01",
  information_number: "010-9185-4557 / 010-9158-4557",
  room_info:
    "TV, 냉장고, 에어컨, 전기밥솥, 전기포트, 인덕션, 드라이기, 주방기구, 일회용 샤워용폼 등",
  amenities: "숯불 및 그릴 이용료 (2~4인) 20,000원 / (5인 이상) 30,000원",
  hours_of_use: "체크인: 오후 3시 이후 / 체크아웃 : 오전 11시 까지",
};

export const refundData_ko = [
  {
    standard: "이용 3일전 ~ 이용당일",
    commission: "100% (환불불가)",
  },
  {
    standard: "이용 4일전",
    commission: "80%",
  },
  {
    standard: "이용 5일전",
    commission: "50%",
  },
  {
    standard: "이용 6일전",
    commission: "40%",
  },
  {
    standard: "이용 7일전",
    commission: "30%",
  },
  {
    standard: "이용 8일전 이후",
    commission: "10%",
  },
];
