import styled from "@emotion/styled";

export const StyledUl = styled.ul`
  .text > strong {
    color: red;
  }
  & > li:first-of-type {
    text-align: right;
    margin-top: -32px;
  }
  & > li:not(first-of-type) {
    margin-top: 15px;
  }
`;
