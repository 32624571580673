import { Typography } from "@mui/material";
import AdaptiveGridContainer from "src/components/atoms/AdaptiveGridContainer";
import RoomCard from "src/components/modules/Card";
import { StyledUl } from "./styles";
import MediaQuery from "src/utils/hooks/useMediaQuery";

export default function RoomsList({ roomsData, onClickRoom }) {
  const { isMobile } = MediaQuery();

  return (
    <AdaptiveGridContainer
      cols={isMobile ? 1 : 3}
      widthGap="24px"
      gap={isMobile ? "24px 0px" : "56px 24px"}
      SquareContent={false}
      contentList={roomsData}
      getContents={(room, index, ref) => {
        const keyword = "HOT";
        const hasBadge = room.feature.includes(keyword);
        let phrase;
        if (hasBadge) {
          const phrases = room.feature.split(keyword);
          phrase = phrases.map((phrase) => {
            if (phrase === "") return <strong>{keyword} </strong>;
            return phrase;
          });
        }

        return (
          <RoomCard
            key={index}
            title={room.name}
            imageSrc={room.thumbnail}
            imageHeight="177.55px"
            alt={"room_image"}
            ref={ref}
            onClick={() => onClickRoom(index)}
            innerContents={
              <StyledUl>
                <li>
                  <Typography variant="body2" color="text.secondary">
                    {room.target}
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    className="text"
                  >
                    {hasBadge ? phrase : room.feature}
                  </Typography>
                </li>
              </StyledUl>
            }
          />
        );
      }}
    ></AdaptiveGridContainer>
  );
}
