import useMediaQuery from "@mui/material/useMediaQuery";

export default function MediaQuery() {
  const media = {
    // isMobile: useMediaQuery("(max-width: 500px)"),

    isMobile: useMediaQuery("(max-width: 541px)"),
    isTablet: useMediaQuery("(max-width: 967.99px)"),
    isPc: useMediaQuery("(min-width: 1080px)"),
  };

  return media;
}
