import { Route } from "react-router-dom";
import { MainPage } from "./pages";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./styles/theme";
import GlobalStyles from "./styles/GlobalStyles";
import { Global } from "@emotion/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import lang from "src/assets/lang";
import { IntlProvider } from "react-intl";
import { flattenMessages } from "src/utils/functions";
import { useEffect } from "react";

import TagManager from "react-gtm-module";
function App(props) {
  const language = navigator.language;
  const tagManagerArgs = {
    gtmId: "GTM-PC7W5NH",
  };

  TagManager.initialize(tagManagerArgs);
  useEffect(() => {
    localStorage.setItem("locale", navigator.language);
  }, [language]);

  return (
    <ThemeProvider theme={theme}>
      <IntlProvider
        locale={language}
        messages={
          lang[language]
            ? flattenMessages(lang[language])
            : flattenMessages(lang["ko_KR"])
        }
      >
        <CssBaseline />
        <Global styles={GlobalStyles} />
        <Route exact path="/" component={MainPage} />
        {/* <Route exact path="/about_us" component={AboutPage} />
        <Route exact path="/rooms" component={RoomsPage} />
        <Route exact path="/specialty" component={SpecialtyPage} />
        <Route exact path="/recreation" component={RecreationPage} />
        <Route exact path="/reservation" component={ReservationPage} /> */}
      </IntlProvider>
    </ThemeProvider>
  );
}

export default App;
