export const homeBannerImages = [
  "../../images/main_1.jpg",
  "../../images/main_2.jpg",
  "../../images/main_3.jpg",
  "../../images/main_4.jpg",
];

export const recreationImages = [
  "../../images/main_1.jpg",
  "../../images/main_2.jpg",
  "../../images/main_3.jpg",
  "../../images/main_4.jpg",
  "../../images/main_1.jpg",
  "../../images/main_2.jpg",
  "../../images/main_3.jpg",
  "../../images/main_4.jpg",
];
