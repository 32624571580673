export const reservatinoInfoHeadCells_ko = [
  { label: "객실명", id: "name" },
  { label: "기준인원", id: "defaultPeopleNum" },
  { label: "최대인원", id: "maxPeopleNum" },
  { label: "비수기 주중(일~목)", id: "offSeason.weekdays" },
  { label: "비수기 주말(금,토)", id: "offSeason.weekend" },
  { label: "성수기", id: "highSeason.weekdays" },
];
export const reservatinoInfoHeadCells_en = [
  { label: "객실명", id: "name" },
  { label: "기준인원", id: "defaultPeopleNum" },
  { label: "최대인원", id: "maxPeopleNum" },
  { label: "비수기 주중(일~목)", id: "offSeason.weekdays" },
  { label: "비수기 주말(금,토)", id: "offSeason.weekend" },
  { label: "성수기", id: "highSeason.weekdays" },
];

export const refundInfoHeadCells_en = [
  { label: "이용일 기준", id: "standard" },
  { label: "수수료", id: "commission" },
];
