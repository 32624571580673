import styled from "@emotion/styled";
import ImageListItem from "@mui/material/ImageListItem";
interface photoType {
  imageWidth: number;
  gap?: string;
  cols?: number;
  extraHeight?: number;
  isClickAvailable: boolean;
}

export const ImageListItemComponent = styled(ImageListItem)`
  &:last-of-type {
    > img {
      height: 100%;
      object-fit: contain;
    }
  }
`;

export const PhotoListComponent = styled.ul<photoType>`
  margin: 16px 0 25.72px;
  width: 100%;
  height: 100%;
  position: relative;

  display: grid;
  grid-template-columns: ${({ gap, cols }) =>
    `repeat(auto-fit, calc((100% - (${gap} * (${cols} - 1))) / ${cols}))`};
  gap: ${({ gap }) => gap && gap};
  cursor: ${({ isClickAvailable }) => isClickAvailable && "pointer"};
`;
