import { useEffect, useRef, useState } from "react";
import useWindowDimensions from "src/utils/hooks/useWindowDimensions";
import { GridList } from "./styles";

export default function AdaptiveGridContainer({
  cols,
  widthGap = "8px",
  SquareContent = false,
  extraHeight = undefined,
  gap = "8px",
  contentList,
  getContents,
}) {
  const imageWidthRef = useRef(null);
  const { windowWidth } = useWindowDimensions();
  const [imageWidth, setImageWidth] = useState(0);

  useEffect(() => {
    imageWidthRef.current &&
      setImageWidth(imageWidthRef.current.getBoundingClientRect().width);
  }, [imageWidthRef, windowWidth]);

  return (
    <GridList
      imageWidth={imageWidth}
      SquareContent={SquareContent}
      widthGap={widthGap}
      extraHeight={extraHeight}
      gap={gap}
      cols={cols}
    >
      {contentList.map(
        (content, index) =>
          content && getContents(content, index, imageWidthRef)
      )}
    </GridList>
  );
}
