import stylesConstants from "src/styles/stylesConstants";
import styled from "@emotion/styled";

interface flexType {
  isMobile: boolean;
}

export const FooterContainer = styled.footer`
  background-color: ${stylesConstants.colors.FOOTER_GREY};
`;

export const FooterLayout = styled.section`
  padding: 22px;
  max-width: 968px;
  margin: 0 auto;
  width: 100%;

  & > article,
  > p,
  > :not(h2) {
    padding-left: 12px;
  }
`;

export const FooterTitle = styled.h2`
  display: flex;
  align-items: center;
`;

export const PolicySection = styled.article`
  margin-bottom: 24px;
`;

export const Divider = styled.div`
  position: relative;
  display: inline-block;
  padding: 0 8px;

  ::after {
    content: "";
    position: absolute;
    bottom: 22%;
    background-color: ${stylesConstants.colors.POINT_GREY};
    width: 1px;
    height: 12px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const PolicyLink = styled.a`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: ${stylesConstants.colors.WHITE};
  cursor: pointer;
`;

export const Information = styled.article`
  margin-bottom: 24px;
`;

export const InfoWrapper = styled.div<flexType>`
  font-size: 11px;
  flex-flow: row ${({ isMobile }) => (isMobile ? "nowrap" : "wrap")};
`;

export const InformationContent = styled.p`
  display: inline;
  position: relative;
  font-size: 11px;
  font-weight: 400;
  color: ${stylesConstants.colors.WHITE};
  line-height: 20px;
  padding-right: 10px;
  letter-spacing: -0.5px;
`;

export const CopyRight = styled.p`
  font-family: "SpoqaHanSansNeo-Regular";
  /* word-break: keep-all; */
  font-size: 11px;
  line-height: 15px;
  max-width: 968px;
  height: 100%;
  margin-bottom: 12px;

  & > h5 {
    color: ${stylesConstants.colors.WHITE};
    font-weight: normal;
    font-style: normal;
  }
  & > p {
    padding-top: 5px;
    color: ${stylesConstants.colors.WHITE};
    font-weight: normal;
    font-style: normal;
  }
`;
