import { ReactFragment, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import useLocale from "./useLocale";

function usePhrase() {
  const intl = useIntl();
  const locale = useLocale();

  const getMsgWithId = useCallback(
    (id, alternative = "Error") =>
      intl.formatMessage({
        id,
        defaultMessage: alternative,
      }),
    [intl]
  );

  const getMsgWithIdAndLocale = useCallback(
    (id) => (
      <FormattedMessage id={id} values={{ locale: locale as ReactFragment }} />
    ),
    [locale]
  );

  return [getMsgWithId, getMsgWithIdAndLocale];
}

export default usePhrase;
