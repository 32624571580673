import styled, { css } from "styled-components";
import Slider from "react-slick";

export const ImageBannerSlider = styled.span`
  /* padding: 8px 22px 40px 22px;
  margin: 32px 0 16px 0; */
  margin-top: 5%;
  display: block;
  position: relative;
  background-color: transparent;

  @media (max-width: 541px) {
    margin-top: 10%;
  }
`;

export const HomeBannerSlider = styled(Slider)`
  ul {
    box-sizing: border-box;
    display: flex;
    position: absolute;
    bottom: 20px;
    li {
      width: auto;
      vertical-align: center;
      align-items: center;
    }
  }

  .slick-dots li button {
    width: 6px;
    height: 6px;
    background: #fff;
    opacity: 0.4;
    padding: 0;
    margin: 0 2px;
    border-radius: 3.5px;
    cursor: pointer;
    transition: all 0.3s;
  }

  .slick-dots li.slick-active button {
    width: 16px;
    height: 6px;
    padding: 0;
    border-radius: 3.5px;
    margin: 0 2px;
    opacity: 1;
    background: #fff;
  }

  .slick-dots li button::before {
    content: "";
    font-size: 0;
  }

  & img {
    /* opacity: 0.8; */
    box-shadow: 2px 6px 22px rgba(0, 0, 0, 0.15);
    max-height: 700px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const NormalBannerSlider = styled(Slider)`
  ul {
    box-sizing: border-box;
    display: flex;
    position: absolute;
    bottom: 20px;
    li {
      width: auto;
      vertical-align: center;
      align-items: center;
    }
  }
  & img {
    /* opacity: 0.8; */
    box-shadow: 2px 6px 22px rgba(0, 0, 0, 0.15);
    max-height: 700px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & img.full {
    height: 100%;
    object-fit: contain;
  }

  .slick-dots li button {
    width: 6px;
    height: 6px;
    background: #fff;
    opacity: 0.4;
    padding: 0;
    margin: 0 2px;
    border-radius: 3.5px;
    cursor: pointer;
    transition: all 0.3s;
  }

  .slick-dots li.slick-active button {
    width: 16px;
    height: 6px;
    padding: 0;
    border-radius: 3.5px;
    margin: 0 2px;
    opacity: 1;
    background: #fff;
  }

  .slick-dots li button::before {
    content: "";
    font-size: 0;
  }
  & button.slick-next:before {
    content: "▶" !important;
    color: white;
    font-size: 20px;
  }
  & button.slick-prev:before {
    content: "◀" !important;
    color: white;
    font-size: 20px;
  }
  ${({ dim }) =>
    dim &&
    css`
      & div > div {
        position: relative;
        background-color: rgb(0, 0, 0);
      }
    `}
`;

// & div > div > img:after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   /* 빈 값으로 넣어야 가상의 요소 생성; */
//   content: "";
//   background: rgba(0, 0, 0, 0.2);
//   border: 1px solid rgba(0, 0, 0, 0.05);
// }
