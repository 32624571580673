import MenuIcon from "@mui/icons-material/Menu";
import { AccordionSummary, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import MediaQuery from "src/utils/hooks/useMediaQuery";
import {
  MenuButton,
  TopNavbar,
  TopToolbar,
  NavbarMenuButton,
  StyledAccordion,
  StyledAccordionDetails,
} from "./styles";
import { useCallback, useEffect, useMemo, useState } from "react";
import usePhrase from "src/utils/hooks/usePhrase";
import { HideOnScroll } from "src/utils/functions";
import useThrottle from "src/utils/hooks/useThrottle";

interface menu {
  href: string;
  label: string;
}
function Header({ scrollRefList }) {
  const { isMobile, isPc } = MediaQuery();
  const [getMsgWithId] = usePhrase();
  const [headerData, setHeaderData] = useState([]);
  const [isActive, setIsActive] = useState(0);
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  const [scrollPos, setScrollPos] = useState(window.scrollY);
  const [isTop, setIsTop] = useState(true);

  useEffect(() => {
    window.addEventListener("scroll", throttleOnScroll);
    return () => {
      window.removeEventListener("scroll", throttleOnScroll);
    };
  }, [scrollPos]);

  const handleScroll = () => {
    const { scrollY } = window;
    scrollY < 20 ? setIsTop(true) : setIsTop(false);
    setScrollPos(scrollY);
  };

  const throttleOnScroll = useThrottle(handleScroll, 100);

  const menuLength = useMemo(
    () => Number(getMsgWithId("menu_length", "0")),
    [getMsgWithId]
  );
  const getHeadersData = useCallback(() => {
    const header = [];
    for (let i = 0; i < menuLength; i++) {
      const menuItem: menu = {
        href: "",
        label: "",
      };
      menuItem.href = getMsgWithId(`menu.${i}.link`) as string;
      menuItem.label = getMsgWithId(`menu.${i}.label`) as string;
      header.push(menuItem);
    }
    return header;
  }, [menuLength, getMsgWithId]);

  useEffect(() => {
    !isNaN(menuLength) && setHeaderData(getHeadersData());
  }, [menuLength, getHeadersData]);

  const onClickMenu = useCallback(
    (index: number) => {
      scrollRefList.current[index] &&
        scrollRefList.current[index].scrollIntoView({ behavior: "smooth" });
      setIsActive(index);
    },
    [scrollRefList]
  );

  const MenuButtons = useMemo(() => {
    return headerData.map(({ href, label }, index) => {
      return (
        <NavbarMenuButton
          {...{
            key: `menu_button_${index}`,
            color: "inherit",
          }}
          className={isActive === index ? "active" : undefined}
          onClick={() => {
            setIsMenuClicked(false);
            onClickMenu(index);
          }}
        >
          {label}
        </NavbarMenuButton>
      );
    });
  }, [headerData, onClickMenu, isActive]);

  return (
    <HideOnScroll isMenuClicked={isMenuClicked}>
      <TopNavbar isMobile={isMobile} className={isTop ? "top" : "not-top"}>
        <TopToolbar isMobile={isMobile} className={isTop ? "top" : "not-top"}>
          {isMobile ? (
            <StyledAccordion expanded={isMenuClicked}>
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <MenuButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={() => setIsMenuClicked((prev) => !prev)}
                >
                  <MenuIcon />
                </MenuButton>
                <Link to="/">
                  <img
                    style={{ marginTop: "6px" }}
                    src="./Logo.png"
                    alt="logo"
                    width="92"
                  />
                </Link>
              </AccordionSummary>
              <StyledAccordionDetails>
                {isMenuClicked && (
                  <Stack direction={"column"} spacing={isPc ? 4 : 2}>
                    {MenuButtons}
                  </Stack>
                )}
              </StyledAccordionDetails>
            </StyledAccordion>
          ) : (
            <>
              <Link to="/">
                <img
                  src="./Logo.png"
                  alt="logo"
                  width="160"
                  style={{ marginTop: "8px" }}
                />
              </Link>
              <Stack direction="row" spacing={isPc ? 4 : 2}>
                {MenuButtons}
              </Stack>
            </>
          )}
        </TopToolbar>
      </TopNavbar>
    </HideOnScroll>
  );
}

export default Header;
