import ImageListItemBar from "@mui/material/ImageListItemBar";
import ImageListItem from "@mui/material/ImageListItem";
import { useEffect, useRef, useState } from "react";
import useWindowDimensions from "src/utils/hooks/useWindowDimensions";
import { ImageListItemComponent, PhotoListComponent } from "./styles";
import MediaQuery from "src/utils/hooks/useMediaQuery";

export default function MasonryImageList({
  cols = 3,
  borderRadius = "0px",
  gap = "8px",
  onClick = undefined,
  images = [],
}) {
  const photoRef = useRef(null);
  const textRef = useRef(null);
  const { windowWidth } = useWindowDimensions();
  const [imageWidth, setImageWidth] = useState(0);
  const [textHeight, setTextHeight] = useState(0);
  const [padding, setPadding] = useState(0);
  const { isMobile, isTablet } = MediaQuery();

  useEffect(() => {
    setPadding(isMobile ? 12 : 0);
    photoRef.current &&
      setImageWidth(photoRef.current.getBoundingClientRect().width);
    textRef.current &&
      setTextHeight(textRef.current.getBoundingClientRect().height);
  }, [photoRef.current, textRef.current, windowWidth]);

  return (
    <PhotoListComponent
      gap={gap}
      cols={cols}
      imageWidth={imageWidth}
      extraHeight={textHeight}
      isClickAvailable={onClick && true}
    >
      {images.map((item, index) => (
        <ImageListItemComponent
          style={{
            borderRadius: borderRadius,
            height: "100%",
            padding: padding,
          }}
          key={item.img}
          onClick={() => onClick(index)}
          ref={photoRef}
        >
          <img
            src={`${item.img}?fit=crop&auto=format`}
            srcSet={`${item.img}?fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            style={{
              borderRadius: borderRadius,
              height: `${imageWidth - padding * 2}px`,
            }}
            loading="lazy"
          />
          <ImageListItemBar
            ref={textRef}
            style={{ height: "100%", textAlign: "center" }}
            position="below"
            title={item.title}
          />
        </ImageListItemComponent>
      ))}
    </PhotoListComponent>
  );
}
