import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import stylesConstants from "src/styles/stylesConstants";

export const GoldText = styled(Typography)`
  color: ${stylesConstants.colors.POINT_GOLD2};
`;

export const GreyText = styled(Typography)`
  color: ${stylesConstants.colors.POINT_STRONG_GREY};
`;

export const FlexRowBox = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

export const FlexRowWrapBox = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

export const FlexColBox = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const FlexColWrapBox = styled.div`
  display: flex;
  flex-flow: column wrap;
`;

export const FlexWrapCenterBox = styled(FlexRowWrapBox)`
  justify-content: center;
  align-items: center;
`;

export const FlexCenterBox = styled(FlexRowBox)`
  justify-content: center;
  align-items: center;
`;

export const FlexColWrapCenterBox = styled(FlexColWrapBox)`
  justify-content: center;
  align-items: center;
`;

export const FlexColCenterBox = styled(FlexColBox)`
  justify-content: center;
  align-items: center;
`;
