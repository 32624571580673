import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import stylesConstants from "src/styles/stylesConstants";
import { forwardRef, ReactNode } from "react";
import { CardActionArea } from "@mui/material";

type TCard = {
  title: string;
  imageSrc?: string;
  alt?: string;
  height?: string;
  imageHeight?: string;
  innerContents: ReactNode;
  onClick?: () => void;
};
function CardComponents(
  {
    title,
    imageSrc,
    alt,
    height,
    imageHeight = "100%",
    innerContents,
    onClick,
  }: TCard,
  ref
) {
  return (
    <Card
      ref={ref}
      onClick={onClick}
      style={{
        cursor: "pointer",
        height,
      }}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          image={imageSrc}
          alt={alt}
          style={{
            height: imageHeight,
          }}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            style={{
              color: stylesConstants.colors.POINT_STRONG_GREY,
              fontWeight: 700,
            }}
          >
            {title}
          </Typography>
          {innerContents}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default forwardRef(CardComponents);
