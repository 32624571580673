import Header from "../components/modules/Header";
import { FormattedMessage } from "react-intl";

export const ReservationPage = () => {
  return (
    <>
      {/* <Header /> */}
      <iframe
        src="https://sambong.nowr-b.net/m_member/?co_id=sambong&ref=&buyer=&m_out=&c_area="
        title="오해피펜션 예약"
        style={{ width: "100vw", height: "100vh" }}
      ></iframe>
    </>
  );
};
