import { useMemo } from "react";
import { Settings } from "react-slick";
import {
  ImageBannerSlider,
  HomeBannerSlider,
  NormalBannerSlider,
} from "./styles";
import MediaQuery from "src/utils/hooks/useMediaQuery";
import ImageContainer from "src/components/atoms/ImageContainer";

interface imageBanner {
  sliderOptions?: any;
  images: any[];
  altBase?: string;
  children?: React.ReactNode;
  dim?: boolean | undefined;
}
function ImageBanner({
  sliderOptions = undefined,
  images = [],
  altBase,
  children,
  dim = false,
}: imageBanner) {
  const sliderImages = images;
  const { isMobile } = MediaQuery();

  const options: Settings = sliderOptions
    ? sliderOptions
    : {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 2000,
        pauseOnDotsHover: true,
        arrows: false, //NOTE: false를 안하면 모바일환경에서 공백이생김
      };

  const Images = useMemo(
    () =>
      sliderImages.map((slideImage, index) => (
        <ImageContainer ratio={60} key={index.toString()}>
          <img
            src={slideImage as string}
            alt={`${altBase}_` + index}
            className={slideImage.includes("보드게임") ? "full" : ""}
          />
        </ImageContainer>
      )),
    [altBase, sliderImages]
  );

  return (
    <ImageBannerSlider>
      {sliderOptions ? (
        <NormalBannerSlider isMobile={isMobile} dim={dim} {...options}>
          {Images}
        </NormalBannerSlider>
      ) : (
        <HomeBannerSlider isMobile={isMobile} dim={dim} {...options}>
          {Images}
        </HomeBannerSlider>
      )}
      {children && children}
    </ImageBannerSlider>
  );
}

export default ImageBanner;
