import styled from "@emotion/styled";

interface AdaptiveGridType {
  imageWidth: number;
  SquareContent: boolean;
  gap?: string;
  widthGap?: string;
  cols?: number;
  extraHeight: string;
}

export const GridList = styled.ul<AdaptiveGridType>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ widthGap, cols }) =>
    cols > 1
      ? `repeat(auto-fit, calc((100% - (${widthGap} * (${cols} - 1))) / ${cols}))`
      : "1fr"};
  grid-auto-rows: ${({ imageWidth, SquareContent, extraHeight }) =>
    SquareContent
      ? extraHeight
        ? `minmax(calc(${imageWidth}px + ${extraHeight}), calc(${imageWidth}px + ${extraHeight}))`
        : `minmax(${imageWidth}px, ${imageWidth}px)`
      : "1fr"};
  gap: ${({ gap }) => gap};
  margin: 23px 0 23px 0;
`;
