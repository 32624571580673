import { useState, useEffect } from "react";

/**
 * browser의 width, height를 반응형에 맞게 리턴해주는 hooks
 * @example const { windowWidth } = useWindowDimensions(); 형태로 사용
 */
function getWindowDimensions() {
  //   const { innerWidth: width, innerHeight: height } = window;
  const { innerWidth: windowWidth, innerHeight: windowHeight } = window;

  return {
    windowWidth,
    windowHeight,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
