export const contents = {
  "ko-KR": [
    "편안한 휴식과 즐거움이 마련되어 있는 곳.",
    "그림같은 산과 계곡이 병풍처럼 둘러쌓인",
    "해피 700 오해피 펜션으로 여러분을 초대합니다!",
  ],
  "en-US": [],
};

export const notice = {
  "ko-KR": [
    "예약 절차 후, 완불 기준으로 예약이 완료됩니다.",
    "무통장 입금의 경우, 12시간 내 입금하셔야 예약이 완료됩니다. 미입금시 12시간 후 자동으로 취소됩니다.",
    "주말요금은 금, 토, 공휴일 전날을 포함합니다. 그 외는 주중 요금이 적용됩니다.",
    "여름 성수기: 매년 7~8월 / 겨울 성수기: 매년 12월 ~ 2월 입니다.",
    "여름 성수기에는 2박 3일 이상 예약이 가능하며, 픽업 서비스가 불가합니다.",
    "예약인원 초과시 1인 30,000원의 요금이 부과되며, 영,유아(12개월 이상)도 기준인원에 포함됩니다.",
    "예약된 인원 외에는 입실이 불가합니다.",
    "단체나 2쌍의 커플, 2쌍의 가족의 경우 사전 합의가 되지 않은 경우 예약 및 이용이 불가합니다.",
    "입실시간은 오후 3시 ~ 10시 이며, 입실시간 이후 입실 시, 사전에 연략을 주셔야하며 입실시간 이전 입실은 불가합니다.",
    "퇴실시간은 성수기 오전 11시, 비수기 오전 12시 입니다.",
    "바베큐 이용시, 그릴 이용 요금은 2~4인 기준 20,000원, 5인 이상 30,000원 입니다.",
    "밤 10시 이후, 공용 외부 시설(수영장, 카페, 공용 바베큐장 등) 이용이 불가합니다.",
    "다른 이용 고객님이 불편함이 없도록 피해를 주는 행동은 자제하여 주시기 바랍니다. (만취, 고성방가 등)",
    "쾌적하고 안락한 펜션 운영 방침에 따라 최대정원 이상 입실은 불가합니다.",
    "미성년자 예약 시 부모님 동의가 있어야 가능합니다.",
    "이용 시 시설물 훼손, 분실에 대한 책임은 투숙객에게 있으며, 고지 의무가 있음을 알립니다.",
    "음식물 쓰레기는 반드시 분리해서 버려주시기 바랍니다.",
    "실내에서는 냄새가 심하게 벨 수 있는 삼겹살 등 고기 및 생선 구이 요리를 금합니다.",
  ],
  "en-US": [],
};
