import styled from "styled-components";

interface Style {
  ratio: number;
  gradiant?: boolean;
}

const ImageContainer = styled.div<Style>`
  position: relative;
  overflow: hidden;
  padding-top: ${({ ratio }) => `${ratio}%`};

  > img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
  }
`;

export default ImageContainer;
