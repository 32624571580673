import { Modal } from "@mui/material";
import { useState } from "react";
import MediaQuery from "src/utils/hooks/useMediaQuery";
import {
  InformationContent,
  InfoWrapper,
  FooterContainer,
  FooterTitle,
  ContentWrapper,
  CopyRight,
  Divider,
  PolicySection,
  PolicyLink,
  Information,
  FooterLayout,
} from "./styles";

export default function Footer() {
  const { isMobile, isTablet } = MediaQuery();
  const [isFooterModalOpen, setIsFooterModalOpen] = useState(false);
  const [footerIndex, setFooterIndex] = useState<number>(0);

  return (
    <FooterContainer>
      <FooterLayout>
        <FooterTitle>
          <img
            src="./Logo_white.png"
            height="50px"
            style={{ margin: "10px", objectFit: "fill" }}
            alt="oh happy pension logo"
          />
          <p>오해피펜션</p>
        </FooterTitle>

        <Information>
          <InfoWrapper isMobile={isMobile}>
            <InformationContent>오해피펜션&emsp;/</InformationContent>
            <InformationContent>대표 : 윤성희&emsp;/</InformationContent>
            <InformationContent>예약문의 : 010-9185-4557</InformationContent>
          </InfoWrapper>

          <InfoWrapper isMobile={isMobile}>
            <InformationContent>
              주소 : 강원도 평창군 방림면 감동지길 116-19
            </InformationContent>
            <InformationContent>
              사업자 등록번호 : 102-10-98279
            </InformationContent>
          </InfoWrapper>
        </Information>
        {/* <PolicySection>
          <ContentWrapper>
            <PolicyLink>이용약관</PolicyLink>
            <Divider />
            <PolicyLink>개인정보취급방침</PolicyLink>
          </ContentWrapper>
        </PolicySection> */}

        <CopyRight>Copyright © 오해피펜션. All Rights Reserved.</CopyRight>
      </FooterLayout>

      {/* <Modal
        style={{ maxWidth: "968px", width: "100%" }}
        isOpen={isFooterModalOpen}
        overflow="none"
        rootID="root-modal"
        onModalClose={() => setIsFooterModalOpen(false)}
        bgrColor="transparent"
      ></Modal> */}
    </FooterContainer>
  );
}
