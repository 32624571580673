import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import stylesConstants from "src/styles/stylesConstants";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: stylesConstants.colors.POINT_GREY_GOLD,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CustomizedTables({
  headCells,
  data,
  isRoomInfo = false,
}) {
  return (
    <TableContainer
      style={{ margin: "12px 0", width: "100%" }}
      component={Paper}
    >
      <Table
        sx={{ minWidth: isRoomInfo ? 500 : 300 }}
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <StyledTableCell align="center">
                {headCell["label"]}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isRoomInfo
            ? data.map((roomData_ko, index) => (
                <StyledTableRow key={roomData_ko.label + index}>
                  <StyledTableCell component="th" scope="row" align="center">
                    {roomData_ko["name"]}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    {roomData_ko["defaultPeopleNum"]}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    {roomData_ko["maxPeopleNum"]}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    {roomData_ko["price"]["offSeason"][
                      "weekdays"
                    ].toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    {roomData_ko["price"]["offSeason"][
                      "weekend"
                    ].toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    {roomData_ko["price"]["highSeason"][
                      "weekdays"
                    ].toLocaleString()}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            : data.map((data_ko, index) => (
                <StyledTableRow key={data_ko.label + index}>
                  {headCells.map((headCell) => (
                    <StyledTableCell component="th" scope="row" align="center">
                      {data_ko[headCell["id"]]}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
