import React, { useEffect, useState, useMemo, useCallback } from "react";
import styled, { CSSObject } from "@emotion/styled";
import { css } from "@emotion/react";
import MediaQuery from "src/utils/hooks/useMediaQuery";
import { ReactComponent as CloseIcon } from "src/assets/icons/close.svg";

interface AnimationType {
  in: string;
  out: string;
}

interface OptionalProps {
  /** Modal의 id값 */
  id?: string;
  /** Modal의 animation시간 */
  animationDuration?: number;
  /** Modal의 width값 */
  width?: number | string;
  /** Modal의 fixed여부(모달이 어떤 모달의 자식으로 들어가야한다면 false를 줘야함) */
  fixed?: boolean;
  /** Modal의 height값 */
  height?: number | string;
  /** Modal의 left값 */
  left?: number | string;
  /** Modal의 right값 */
  right?: number | string;
  /** Modal의 top값 */
  top?: number | string;
  /** Modal의 bottom값 */
  bottom?: number | string;
  /** Modal의 radius */
  radius?: number;
  /** Modal의 background-color 표시유무 */
  noBgr?: boolean;
  /** Modal이 open됐을때 background-color 컬러값 */
  bgrColor?: string;
  /** Modal이 zIndex갑 */
  zIndex?: number;
  /** Modal의 box-shadow 값 */
  boxShadow?: string;
  /** Modal이 open됐을때 scroll hide유무  */
  isBodyScrollHide?: boolean;
  /** Modal에 border를 결정 */
  isBorder?: boolean;
  /** Modal의 X버튼 표시유무 */
  // hideCloseIcon?: boolean;
  /** Modal의 mount,unMount때의 애니메이션 */
  animation?: AnimationType;
  /** Modal의 말풍선 css */
  ballonStyle?: CSSObject;
  /** Modal의 close를 눌렀을때 작동하는 함수 */
  onModalClose?: () => void;
  /** Modal이 개별영역을 차지할지 풀영역을 차지할지를 결정 */
  isFullMode?: boolean;
  /** X버튼이 상단에 타이틀과 같이보여질지 결정 */
  closeBtnFixed?: boolean;
  overflow?: "auto" | "none";
  style?: CSSObject;
  isCloseButtonUnderContent?: boolean;
  // type: any;
}

export interface Props extends OptionalProps {
  /** Modal의 open여부 */
  isOpen: boolean;
  children: React.ReactNode | React.ReactElement;
}

function ModalPortal({
  isOpen,
  animationDuration = 0.25,
  width = 400,
  height = "auto",
  overflow = "auto",
  left,
  id,
  right,
  top,
  zIndex = 0,
  bottom,
  ballonStyle = {},
  radius = 6,
  noBgr = false,
  fixed = true,
  isBodyScrollHide = true,
  bgrColor = "white",
  boxShadow,
  isBorder = false,
  animation = {
    in: `0% {transform: translateY(50px); opacity:0} 100% {transform: translateY(0px); opacity:1}`,
    out: `0% {transform: translateY(0px); opacity:1} 100% {transform: translateY(50px); opacity:0}`,
  },
  onModalClose,
  isFullMode = true,
  children,
  style,
  isCloseButtonUnderContent = false,
}: // type,
Props) {
  const { isMobile } = MediaQuery();
  const [state, setState] = useState({
    // modal animation을 위한 임시 변수값
    isClose: false,
    // modal의 open을 결정하는 임시 변수(modal 끝낫을때 애니메이션을위해)
    tempIsOpen: isOpen,
    // modal이 한번이라도 보여졌는지를 결정
    hasShowed: false,
  });

  const { isClose, tempIsOpen, hasShowed } = state;

  const isModalOpen = useMemo(
    () => (isFullMode ? isOpen : tempIsOpen),
    [isFullMode, isOpen, tempIsOpen]
  );

  // 전달받은 animation을 @emotion/styled에 맞게 변환
  const modalAnimation = useMemo(() => {
    return {
      in: `${animation.in}`,
      out: `${animation.out}`,
    };
    // eslint-disable-next-line
  }, [animation?.in, animation?.out]);

  // modal close 함수(애니메이션 처리와 같이 이루어짐)
  const onClose = useCallback(() => {
    // 미리 close값을 true로 바꿔서 엔딩 애니메이션을 작동하도록함
    setState((prev) => ({
      ...prev,
      isClose: true,
    }));

    // 끝나는 애니메이션을 위해 실질적으로 500ms뒤에 modal을 unmout시킴
    setTimeout(() => {
      if (onModalClose) onModalClose();
      setState((prev) => ({
        ...prev,
        isClose: false,
        tempIsOpen: false,
      }));
    }, 200);
  }, [onModalClose]);

  useEffect(() => {
    if (isOpen) {
      setState((prev) => ({
        ...prev,
        tempIsOpen: isOpen,
        hasShowed: true,
      }));
      // modal이 fixed일때는 body의 스크롤을 없앰
      if (isBodyScrollHide) document.body.style.cssText = `overflow:hidden;`;
    } else if (hasShowed && !isFullMode) {
      onClose();
    }

    // 스크롤을 다시 만듬
    return () => {
      if (isOpen && isBodyScrollHide) {
        //   const scrollY = document.body.style.top;
        document.body.style.cssText = `overflow:undefined;`;
        //   window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
      }
    };
  }, [isOpen, isBodyScrollHide, hasShowed, onClose, isFullMode]);

  return isModalOpen ? (
    <Container
      fixed={fixed}
      isFullMode={isFullMode}
      left={left}
      top={top}
      right={right}
      bottom={bottom}
      style={!fixed ? (style as any) : undefined}
      className="modal-container"
    >
      {!isClose && isFullMode && (
        <Overlay noBgr={noBgr!} onClick={onClose} className="modal-overlay" />
      )}
      <Wrapper
        isFullMode={isFullMode}
        right={right}
        width={width}
        height={height}
        left={left}
        top={top}
        style={fixed ? (style as any) : undefined}
        bottom={bottom}
        className="modal-wrapper"
      >
        <ContentWrapper
          id={id}
          animationDuration={animationDuration}
          zIndex={zIndex}
          modalAnimation={isClose ? modalAnimation.out : modalAnimation.in}
          ballonStyle={ballonStyle}
          className="modal-content-wrapper"
        >
          <Content
            overflow={overflow}
            isBorder={isBorder}
            radius={radius!}
            bgrColor={bgrColor}
            boxShadow={boxShadow}
            className="modal-content"
          >
            <ChildrenWrapper className="modal-children-wrapper">
              {/* <img
                className="close"
                src="./close.png"
                alt="close"
                style={{ width: "25px", margin: "4px", float: "right" }}
                onClick={onModalClose}
              /> */}
              <CloseIcon
                fill="#fff"
                width={isMobile ? 16 : 24}
                height={isMobile ? 16 : 24}
                className="close"
                style={{ width: "25px", margin: "4px", float: "right" }}
                onClick={onModalClose}
              />
              {children}
            </ChildrenWrapper>
          </Content>
        </ContentWrapper>
      </Wrapper>
    </Container>
  ) : null;
}

// 전체화면
const Container = styled.div<OptionalProps>`
  overflow: scroll;

  ${({ isFullMode, fixed, top, left, bottom, right }) =>
    isFullMode
      ? css`
          position: ${fixed ? "fixed" : "absolute"};
          display: flex;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          z-index: 9999999;
          transition: 0.15s;
        `
      : css`
          position: ${fixed ? "fixed" : "absolute"};
          z-index: 9999999;
          top: ${top && top}px;
          left: ${left && left}px;
          bottom: ${bottom && bottom}px;
          right: ${right && right}px;
          transform: ${typeof top === "string" &&
          typeof left === "string" &&
          `translate(-${left && left}px, -${top && top}px)`};
        `}
`;

const Wrapper = styled.div<OptionalProps>`
  position: relative;
  margin: auto;
  width: ${({ width }) => width!}px;
  height: ${({ height }) => height!}px;
  //z-index: 999;

  ${({ isFullMode, top, left, bottom, right }) =>
    isFullMode
      ? css`
          margin-top: ${top && top}px;
          margin-left: ${left && left}px;
          margin-bottom: ${bottom && bottom}px;
          margin-right: ${right && right}px;
        `
      : css``}
`;
const ContentWrapper = styled.div<OptionalProps & { modalAnimation }>`
  position: relative;
  height: inherit;
  /* width: inherit; */
  z-index: ${({ zIndex }) => 99 + zIndex!};
  animation: ${({ modalAnimation, animationDuration }) =>
    css`
      ${modalAnimation!} ${animationDuration}s ease-out forwards
    `};

  ::after {
    content: "";
    position: absolute;
    z-index: 9999;
    ${({ ballonStyle }) =>
      css`
        ${ballonStyle}
      `};
  }
`;

const Content = styled.div<OptionalProps>`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: ${({ overflow }) => overflow};
  background-color: ${({ bgrColor }) => bgrColor};
  box-shadow: ${({ boxShadow }) => boxShadow};
  border: ${({ isBorder }) => isBorder && "1px solid rgba(0, 0, 0, 0.15)"};
  box-sizing: border-box;
`;

const Overlay = styled.div<OptionalProps>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ noBgr }) =>
    noBgr ? "transparent" : "rgba(0,0,0,.7)"};
`;

const ChildrenWrapper = styled.div`
  height: inherit;
  width: 100%;
`;

export default ModalPortal;
