import styled from "@emotion/styled";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Accordion,
  AccordionDetails,
} from "@mui/material";
import stylesConstants from "src/styles/stylesConstants";

interface responsive {
  isMobile: boolean;
  children: React.ReactNode;
}

export const TopNavbar = styled(AppBar)<responsive>`
  position: sticky;
  height: 100%;
  border-radius: ${({ isMobile }) => (isMobile ? "none" : "10px")};
  top: ${({ isMobile }) => (isMobile ? "0" : "1.5%")};
  margin: ${({ isMobile }) => (isMobile ? "0" : "0 auto")};
  width: ${({ isMobile }) => (isMobile ? "100%" : "98%")};
  max-width: ${stylesConstants.size.PC_CONTENT_WIDTH};
  background: transparent;
  .Mui-expanded {
    margin: 5px 0 20px 0;
  }

  &.top {
    top: 0;
    width: 100%;
    border-radius: none;
    box-shadow: none;
  }
`;

export const TopToolbar = styled(Toolbar)<responsive>`
  padding: ${({ isMobile }) => (isMobile ? "0 12px" : "12px")};
  border-radius: ${({ isMobile }) => (isMobile ? "none" : "10px")};
  display: flex;
  justify-content: space-around;
  flex-flow: row nowrap;
  width: 100%;
  background-color: #fff;

  box-shadow: ${({ isMobile }) => (isMobile ? "0px 0px 4px 2px #fff" : "none")};
  color: #383838;

  &.top {
    border-radius: none;
    box-shadow: none;
  }
`;

export const StyledAccordion = styled(Accordion)`
  border-radius: 0;
  width: 100%;
  background-color: transparent;
  box-shadow: none;
  & > div {
    padding: 0;
    margin: 0;
  }
  & > * > div {
    margin: 0;
    margin-top: 6px;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  /* background-color: ${stylesConstants.colors.POINT_STRONG_GREY}; */
  background-color: #ffffff;
  /* box-shadow: 0px 0px 10px 3px ${stylesConstants.colors
    .POINT_STRONG_GREY}; */
  border-radius: 2%;
  margin-bottom: 3%;
`;

export const MenuButton = styled(IconButton)`
  justify-self: flex-start;
  padding: 0;
  margin: 0 auto 0 0;
`;

export const TextLogo = styled(Typography)`
  text-transform: capitalize;
  justify-self: flex-start;
  margin: 0 auto 0 0;
  color: ${stylesConstants.colors.POINT_STRONG_GREY};
  font-weight: 600;
`;

export const NavbarMenuButton = styled(Button)`
  text-transform: uppercase;
  border-radius: 0;
  border-bottom: ${stylesConstants.colors.GHOST} 2px solid;
  /* color: ${stylesConstants.colors.POINT_STRONG_GREY}; */
  font-weight: 600;
  /* TODO: transition 추가하기 */
  &.active {
    border-bottom: ${stylesConstants.colors.POINT_GOLD2} 2px solid;
  }
`;
