import { css } from "@emotion/react";
import stylesConstants from "./stylesConstants";

const GlobalStyles = css`
  html,
  body {
    padding: 0;
    margin: 0;
    font-family: "SpoqaHanSansNeo-Regular", "Noto Sans KR", sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${stylesConstants.colors.POINT_STRONG_GREY};
  }

  p,
  div,
  span {
    color: ${stylesConstants.colors.POINT_GREY};
  }

  @font-face {
    font-family: "Noto Sans KR";
    src: url("/fonts/NotoSansKR.otf") format("otf");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "SpoqaHanSansNeo-Regular";
    src: url("/fonts/SpoqaHanSansNeo-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "SpoqaHanSansNeo-Medium";
    src: url("/fonts/SpoqaHanSansNeo-Medium.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  .active {
    opacity: 1;
  }

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    /* font-size: 100%; */
    list-style: none;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    font-size: 100%;
  }

  img {
    object-fit: cover;
  }

  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
    &[type="file"] {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }

    input[type="text"]:disabled {
      background: #eee;
    }

    &:-internal-autofill-selected {
      background-color: transparent !important;
    }
  }

  body {
    font-family: "SpoqaHanSansNeo-Regular", "Noto Sans KR", sans-serif;
    box-sizing: border-box;
    /* font-size: 100%; */
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }
  ol,
  ul,
  li {
    list-style: none;
  }
  a {
    /* display: block; */
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }
  *:focus {
    outline: none;
  }
  input {
    padding: 0;
    border: none;

    &:focus {
      outline: none;
    }

    &::placeholder {
      font-family: "SpoqaHanSansNeo-Regular", "Noto Sans KR", sans-serif;
      font-weight: bold;
      vertical-align: middle;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.25);
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }

    &:-internal-autofill-selected {
      background-color: transparent !important;
    }
  }

  button {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
  }

  button:disabled,
  button[disabled] {
    background-color: rgba(0, 0, 0, 0.2);
    color: #666666;
    cursor: not-allowed;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  #root-modal {
    position: relative;
    z-index: 99999;
  }

  textarea {
    font-family: inherit;
  }
  body {
    -ms-overflow-style: none;
  }

  //Custom React-Slick
  .slick-dots {
    bottom: 6px;
  }
  .slick-dots li {
    width: auto;
    height: auto;
    margin: 0 2px;
    vertical-align: middle;
  }

  .slick-next:before {
    content: "" !important;
  }

  .slick-prev:before {
    content: "" !important;
  }
`;

export default GlobalStyles;
