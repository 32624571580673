import { useEffect, useRef, useState } from "react";
import MediaQuery from "src/utils/hooks/useMediaQuery";
import useWindowDimensions from "src/utils/hooks/useWindowDimensions";
const { kakao } = window;

export const initialMapParams = {
  latitude: 37.48018724994263,
  longitude: 128.27488542047723,
  zoomLevel: 2,
};

const KakaoMap = () => {
  const mapRef = useRef(null);
  const { windowWidth } = useWindowDimensions();
  const { isMobile } = MediaQuery();
  const [mapWidth, setMapWidth] = useState(0);

  useEffect(() => {
    isMobile
      ? setMapWidth(mapRef.current.getBoundingClientRect().width)
      : setMapWidth(
          mapRef.current.getBoundingClientRect().width -
            mapRef.current.getBoundingClientRect().width / 2
        );
  }, [mapRef.current, windowWidth]);

  useEffect(() => {
    console.log("kakao", kakao);
    if (mapRef.current) {
      const container = mapRef.current;
      const options = {
        center: new kakao.maps.LatLng(
          initialMapParams.latitude,
          initialMapParams.longitude
        ),
        level: initialMapParams.zoomLevel,
      };

      const map = new kakao.maps.Map(container, options);

      // 마커가 표시될 위치입니다
      const markerPosition = new kakao.maps.LatLng(
        37.48018724994263,
        128.27488542047723
      );

      const marker = new kakao.maps.Marker({
        position: markerPosition,
      });

      marker.setMap(map);
      // map.setDraggable(false);
      // map.setZoomable(false);
    }
  }, [mapRef.current, kakao]);

  return (
    <div
      ref={mapRef}
      style={{
        width: "100%",
        height: `${mapWidth}px`,
      }}
    ></div>
  );
};

export default KakaoMap;
