import React from 'react';
import ReactDOM from 'react-dom';
import ModalPortal, { Props as ModalProps } from './ModalPortal';

export interface Props extends ModalProps {
  /** Modal mount시킬 엘리먼트의 id */
  rootID: string;
}

function Modal({ rootID, isOpen, ...props }: Props) {
  // if (typeof document !== "undefined") {
  const rootModalEl = document.getElementById(rootID);

  return (
    rootModalEl && ReactDOM.createPortal(<ModalPortal isOpen={isOpen} {...props} />, rootModalEl)
  );
}
// return "";
// }

export default Modal;
